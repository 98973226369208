* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden!important;
}

.custom-switch.ant-switch-checked {
    background-color: var(--color-primario)!important;
}

.custom-switch.ant-switch:not(.ant-switch-checked) {
    background-color: transparent;
}